<template>
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <div v-if="dataInfo" id='goodsinfo'>
      <!-- 轮播 -->
      <div class="swiper">
        <van-swipe :autoplay="5000" :style="{height: vwHeight}" indicator-color="white" id="swiper">
          <van-swipe-item v-for="(item, index) in dataInfo.pics" :key="index">
            <van-image :src="item.url" fit="contain" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <!-- 商品说明 -->
      <div class="good-info">
        <div class="good-prices">
          $<span class="good-price">{{dataInfo.sale_price}}</span>
        </div>
        <div class="good-title">
          <div class="good-text">
            <span v-if="dataInfo.tags.length > 0" class="good-status" :class="{'activity': isActivity}">{{dataInfo.tags[0].name}}</span>
            {{dataInfo.title}}
          </div>
        </div>
        <div class="goods-explain">{{dataInfo.desc}}</div>
      </div>
      <!-- 单元格 -->
      <div class="goods-prefer">
        <div class="goods-cell van-hairline--bottom">
          <div class="goods-cell-header">
            <!-- 服务政策 -->
            <div class="goods-cell-title">{{language.l_70}}</div>
            <div class="goods-cell-active">
              <van-icon name="ellipsis" size="16px" color="#999999"/>
            </div>
          </div>
          <!-- 正品保障，假一罚十 -->
          <div class="goods-cell-desc"><span>{{language.l_71}}</span></div>
        </div>
        <div @click="openAsk" class="goods-cell van-hairline--bottom">
          <div class="goods-cell-header">
            <!-- 消费者告知书 -->
            <div class="goods-cell-title">{{language.l_72}}</div>
            <div class="goods-cell-active">
              <!-- 查看全部 -->
              <span>{{language.l_73}}</span>
              <van-icon name="arrow" size="16px" color="#999999"/>
            </div>
          </div>
          <!-- 用户须知 · 跨境答疑 · 价格说明 -->
          <div class="goods-cell-desc"><span>{{language.l_74}}</span></div>
        </div>
      </div>
      <!-- tab -->
      <!-- 商品详情 -->
      <div class="tab">——— {{language.l_75}} ———</div>
      <div class="tabs">
        <div v-html="contentText" class="g_cont"></div>
      </div>
      <!-- 提交 -->
      <van-goods-action>
        <!-- 首页 -->
        <van-goods-action-icon icon="wap-home-o" :text="language.l_65" :to="{name: 'Home'}"/>
        <!-- 购物车 -->
        <van-goods-action-icon icon="shopping-cart-o" :text="language.l_6" :to="{name: 'Cart'}"/>
        <!-- 加入购物车 -->
        <van-goods-action-button :text="language.l_76" @click="openSku(language.l_76)"/>
        <!-- 立即购买 -->
        <van-goods-action-button :text="language.l_77" @click="openSku(language.l_77)"/>
      </van-goods-action>
       <!-- 选择sku -->
      <van-action-sheet v-model="skuFlag" get-container="#app" class="goods-sheet">
        <div class="model">
          <div class="model-header van-hairline--bottom">
            <div class="model-goodImg">
              <van-image :src="dataInfo.pics[0].url" />
            </div>
            <div class="model-info">
              <template>
                <p class="model-price">$<span class="model-bigSize">{{selectGood.vip_sale_price}}</span><span v-if="vipFlag" class="vip_level">{{language.l_317}}</span></p>
                <!-- 数量： -->
                <p v-if="vipFlag" class="model-number">{{language.l_316}}<span>${{selectGood.sale_price}}</span></p>
                <p v-else class="model-number">{{language.l_78}}{{goodNum}} {{dataInfo.unit_name}}</p>
              </template>
              <div class="model-sku">{{getSelectSkuName}}</div>
            </div>
          </div>
          <!-- 规格和组合子集-->
          <div class="model-wrap">
            <div class="model-specs">
              <!-- 组合 -->
              <template>
                <div class="model-item van-hairline--bottom">
                  <!-- 商品规格 -->
                  <p class="model-item-title">{{language.l_79}}</p>
                  <div class="model-item-wrap">
                    <p v-for="(item, index) in dataInfo.goods_detail" :key="item.id" @click="selectSku(index)" :class="{'model-active': item.id == selectGood.id, 'activity': isActivity}">
                      {{item.product_name}}
                    </p>
                  </div>
                </div>
              </template>
            </div>
            <!-- 商品数量 -->
            <van-cell :title="language.l_80" center>
              <template v-slot:default>
                <div class="model-input">
                  <van-stepper v-model="goodNum" :max="selectGood.stock" integer disable-input/>
                </div>
              </template>
            </van-cell>
          </div>
        </div>
        <div @click="onSubmit" :class="{'activity': isActivity}" class="model-btn">{{text}}</div>
      </van-action-sheet>
      <!-- 消费者告知书 -->
      <van-popup class="pop-explain" v-model="askFlag" get-container="#app">
        <div class="popup">
          <!-- 消费者告知书 -->
          <span class="pop-title">{{language.l_81}}</span>
          <!-- 尊敬的用户：为了您更好地选择境外物品，请您在下单前务必认真详细阅读并完全理解本告知书相关内容，对自身风险作出客观判断，完全同意本告知书内容后再下单。 -->
          <span class="pop-subtitle">{{language.l_82}}</span>
          <!-- 1、您通过本网站购买的所有境外物品等同于境外购买。 -->
          <span>{{language.l_90}}</span>
          <!-- 2、您购买的所有境外物品适用的品质、健康、安全、卫生、环保、标示等项目标准可能与我国质量安全标准不同，由此可能产生的危害或损失以及法律责任或其他风险，将由您个人承担。 -->
          <span>{{language.l_83}}</span>
          <!-- 3、您在本站购买的境外物品可能无中文标签或说明书，请在下单前认真阅读平台提供的物品信息。 -->
          <span>{{language.l_84}}</span>
          <!-- 4、建议您对境外物品有一定了解并评估是否适合自己使用后再下单，感谢您的配合。 -->
          <span>{{language.l_85}}</span>
        </div>
      </van-popup>
    </div>
  </van-pull-refresh>
</template>

<script>
import {getGoodsInfo} from '@api'

export default {
  data() {
    return {
      refreshing: false, //下拉刷新
      height: '375',
      isActivity: false,
      askFlag: false, // 答疑弹窗
      skuFlag: false, // sku弹窗
      selectGood: {}, // 选择的商品
      dataInfo: null,
      goodNum: 1,
      text: '',  //立即购买
      isLogin: this.$store.state.token,
      language: this.$store.getters.language,
      vipFlag: false // vip等级
    }
  },
  created() {
    this.getData();
    const {userInfo} = this.$store.state;
    if(!userInfo) return;
    this.vipFlag = userInfo.vip_level ? true : false;
  },
  computed: {
    vwHeight() {  //计算轮播图高度
      const viewportWidth = 375 / 100;
      return this.height / viewportWidth + 'vw'
    },
    contentText() {return this.dataInfo.content.replace(/<br>/gi, '')},
    getSelectSkuName() {  //获取选择的Sku名称
      let newName = this.selectGood.product_name
      if(!newName) {newName = ''}
      return this.language.l_86 + newName  //已选：
    }
  },
  methods:{
    onRefresh() { // 下拉刷新
      setTimeout(() => {
        this.refreshing = false;
        this.getData()
        this.$toast.success(this.language.l_314)  //刷新成功
      }, 1000);
    },
    openAsk() { // 显示消费者公告弹窗
      this.askFlag = true;
    },
    openSku(text) {  //显示Sku
      if(!this.isLogin) return this.$router.push({name: 'Login'})
      const {goods_detail, pics, title} = this.dataInfo;
      this.selectGood = goods_detail[0];
      this.selectGood.pics = pics
      this.selectGood.goods_title = title

      if(text == this.language.l_76) {  //加入购物车
        this.text = this.language.l_87  //确定
      }else if (text == this.language.l_77) {  //立即购买
        this.text = this.language.l_77  //立即购买
      }
      this.skuFlag = true;
    },
    onSubmit() { //确定
      this.skuFlag = false
      if(this.text == this.language.l_87) {  //确定
        this.addCart()
      }else if (this.text == this.language.l_77) {  //立即购买
        this.toOrderCheck()
      }
    },
    selectSku(index) { // 选择组合sku
      const {goods_detail, pics, title} = this.dataInfo;
      this.selectGood = goods_detail[index]
      this.selectGood.pics = pics
      this.selectGood.goods_title = title
    },
    getData() {
      const {id} = this.$route.params;
      getGoodsInfo(id).then(res => {
        this.dataInfo = res.data;
      }).catch(err => {
        this.$toast(err)
        this.$router.go(-1)
      })
    },
    addCart() {  //加入购物车
      this.selectGood.product_num = this.goodNum
      const {goods_title, goods_id, pics, product_code, product_name, product_num: number, sale_price, producing_area, id, skus, product_type, stock} = this.selectGood
      const params = {
        goods_title,
        goods_id,
        pics,
        goods_pic: pics[0].url,
        product_code,
        product_name,
        number,
        sale_price,
        producing_area,
        id,
        skus,
        product_type,
        product_id: id,
        stock
      }
      let list = JSON.parse(localStorage.getItem('cartList'))
      if(!list) {list = []}
      var flag = false 
      list.some(item => {
        if(item.id == params.id && item.product_name == params.product_name) {
          item.number += parseInt(params.number)
          flag = true
          return true
        }
      }) 
      if(!flag) {list.push(params)}
      this.$toast.success(this.language.l_89);  //添加成功
      this.$store.commit('addToCart', list)
      localStorage.setItem('cartList', JSON.stringify(list))
    },
    toOrderCheck() {  //提交订单
      const {dataInfo, selectGood, goodNum} = this
      const {desc, title: goods_title, pics, id: goods_id} = dataInfo
      const {id: product_id, product_type, product_name, product_code, sale_price, skus} = selectGood
      let goods_amount = goodNum * sale_price
      let skus_1 = ''
      if(skus) {skus_1 = JSON.parse(skus)}
      let products = [{
        desc,
        goods_id,
        goods_title,
        goods_pic: pics[0].url,
        product_id,
        product_type,
        product_name,
        product_code,
        number: goodNum,
        skus: skus_1,
        sale_price,
      }]
      let params = {goods_amount, products}
      this.$store.commit('setOrderCheck', params)
      this.$router.push({name: 'OrderCheck'})
    }
  }
}
</script>

<style lang='less' scoped>
#goodsinfo {
  background-color: #f4f4f4;
  .safe_padding_bottom(50px);
  .good-info {
    box-sizing: border-box;
    width: 100%;
    padding: 0 15px 0 10px;
    background-color: #fff;
    overflow: hidden;
    .good-prices {
      font-size: 14px;
      color: red;
      padding-top: 7px;
      .good-price {
        font-size: 20px;
      }
      .good-tax {
        font-size: 12px;
        color: #999999;
        margin-left: 5px;
        margin-right: 2px;
        .good-bold {
          color: #333;
        }
      }
      .goods_profit {
        margin-left: 5px;
        color: #fff;
        background-image: @grad_left;
        padding: 0 4px;
        border-radius: 3px;
        font-size: 12px;
        &.activity {
          background-image: @grad_act_right;
        }
      }
    }
    .good-title {
      display: flex;
      align-items: center;
      margin-top: 5px;
      color: #333333;
      line-height: 1.5;
      .good-text {
        flex: 1;
        .good-status {
          display: inline-block;
          // width: 37px;
          // height: 14px;
          // line-height: 14px;
          padding: 1px 8px;
          font-size: 10px;
          text-align: center;
          color: #ffffff;
          border-radius: 6px;
          background-color: @c_deep;
          &.activity {
            background-image: @grad_act_right;
          }
        }
      }
      .good-handles {
        .good-collect {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 40px;
          font-size: 12px;
          .van-icon-star {
            color: orange;
          }
          span {
            margin-top: 3px;
          }
        }
      }
      
    }
    .goods-explain {
      box-sizing: border-box;
      padding-bottom: 5px;
      margin-top: 5px;
      line-height: 1.5;
      font-size: 12px;
      color: #999999;
    }
  }
  .goods-prefer {
    width: 100%;
    background-color: #FFF;
    margin-top: 10px;
    .goods-cell {
      box-sizing: border-box;
      padding: 10px;
      font-size: 12px;
      color: #999999;
      .goods-cell-header {
        display: flex;
        justify-content: space-between;
        .goods-cell-title {
          font-size: 14px;
          color: #333333;
        }
        .goods-cell-active {
          display: flex;
          align-items: center;
          span {
            display: inline-block;
          }
          .goods-cell-tag {
            padding: 2px 5px;
            text-align: center;
            font-size: 10px;
            color: red;
            &.van-hairline--surround::after {
              border-color: red;
              border-radius: 6px;
            }
            .van-icon {
              vertical-align: middle;
              margin-bottom: 2px;
            }
          }
        }
      }
      .goods-cell-desc {
        margin-top: 5px;
      }
    }
  }
  .tab {
    height: 30px;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    color: #999;
  }
  .tabs {
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #fff;
    .g_cont {
      background: #fff;
      /deep/ img {
        display: block;
        width: 100%;
      }
    }
  }
  .van-goods-action {
    z-index: 999;
    .van-button {
      color: #fff;
    }
    .van-goods-action-button--first {
      background: @c_light;
      border-color: @c_light;
    }
    .van-goods-action-button--last {
      background: @c_deep;
      border-color: @c_deep;
    }
    .van-goods-action-icon {
      /deep/ .van-icon-star {
        color: orange;
      }
    }
  }
}

.vip_level {
  margin-left: 5px;
  padding: 1px 4px;
  color: #fff;
  background: @grad_left;
  border-radius: 4px;
  font-size: 14px;
}
.model-number span{
  text-decoration:line-through;
}
</style>